import React, { useEffect, useState, useRef } from 'react'
import * as TopBannerStyles from './../TopBanner/TopBanner.module.scss'
import classNames from 'classnames'
import { headerEvents } from '../../../lib/utils'

interface LeaderboardProps {
  adSlot: string
  sticky?: boolean
  order?: number
}

const Leaderboard = ({ adSlot, sticky, order }: LeaderboardProps) => {
  const [adition, setAdition] = useState<any>(null)
  const [adLoaded, setAdLoaded] = useState<boolean>()
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  // const [topOverride, setTopOverride] = useState<number | null>(null)

  const [headerHeight, setHeaderHeight] = useState<number | null>(null)
  const [collapsed, setCollapsed] = useState<boolean>(false)

  useEffect(() => {
    const handleHeightChange = (newHeight: number | null) => {
      setHeaderHeight(newHeight)
    }

    const handleStateChange = (newState: string) => {
      if (newState === 'collapsed' && window.scrollY > 500) {
        setCollapsed(true)
      } else {
        setCollapsed(false)
      }
    }

    headerEvents.on('heightChanged', handleHeightChange)
    headerEvents.on('headerState', handleStateChange)

    return () => {
      headerEvents.off('heightChanged', handleHeightChange)
      headerEvents.off('headerState', handleStateChange)
    }
  }, [])

  useEffect(() => {
    if (!adLoaded && adition && adition !== null) {
      setAdLoaded(true)
      // eslint-disable-next-line
      // @ts-ignore
      const qded = adition
      qded.srq.push(function (api: any) {
        api.renderSlot(adSlot)
      })
    }
  }, [adition])

  useEffect(() => {
    const checkAdition = () => {
      if (typeof window !== 'undefined' && 'adition' in window) {
        if ('ready' in window.adition) {
          if (intervalRef.current) {
            setAdition(window.adition)
            clearInterval(intervalRef.current) // Clear the interval if it has been set
          }
        }
      }
    }

    intervalRef.current = setInterval(checkAdition, 1000) // Check every second

    return () => {
      // Clean up on component unmount
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [])

  const elementRef = useRef<HTMLDivElement>(null)
  const [shouldShowClass, setShouldShowClass] = useState<boolean>(false)
  const [stickyFinished, setStickyFinished] = useState<boolean>(false)
  const [slideDown, setSlideDown] = useState<boolean>(false)

  const isSticky = () => {
    const scrollTop = window.scrollY
    const stickyClass = scrollTop >= 210 ? true : false
    setSlideDown(stickyClass)
  }

  useEffect(() => {
    if (!elementRef.current) return
    if (!adLoaded) return
    if (!sticky) return

    if (adSlot === 'TopLB') window.addEventListener('scroll', isSticky)

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !stickyFinished) {
          setShouldShowClass(true)
          setTimeout(() => {
            setShouldShowClass(false)
            setTimeout(() => {
              setStickyFinished(true)
              if (elementRef.current) observer.unobserve(elementRef.current)
            }, 1000)
          }, 3000) // remove class after 3 seconds
        }
      },
      {
        threshold: 1.0 // element is fully visible
      }
    )

    observer.observe(elementRef.current)

    // Cleanup
    return () => {
      if (elementRef.current) observer.unobserve(elementRef.current)
      if (adSlot === 'TopLB') window.removeEventListener('scroll', isSticky)
    }
  }, [adLoaded])

  return (
    <div
      className={classNames({
        [TopBannerStyles.Wrapper]: true,
        [TopBannerStyles.LB]: sticky,
        // [TopBannerStyles.Sticking]:
        //   (sticky && adSlot === 'TopLB') ||
        //   (sticky && shouldShowClass && !stickyFinished),
        [TopBannerStyles.Unstuck]:
          sticky && !shouldShowClass && !stickyFinished && adSlot !== 'TopLB',
        [TopBannerStyles.TopLB]: adSlot === 'TopLB' && slideDown
      })}
      ref={elementRef}
      style={
        {
          '--order': order ?? 0,
          top:
            headerHeight !== null
              ? collapsed
                ? `-100%`
                : `${headerHeight}px`
              : 0
        } as React.CSSProperties
      }
    >
      <div id={adSlot}></div>
    </div>
  )
}

export default Leaderboard
