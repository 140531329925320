import React, { useState, useEffect, useContext } from 'react'
import { graphql, navigate } from 'gatsby'
import FeatherDown from '../../../static/images/school-reviews/feather-down-revert.svg'
import Header from '../../components/Header/Header'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Footer from '../../components/Footer/Footer'
import CTARow from '../../components/CTA/CTARow'
import Blocks from '../../components/Blocks/Blocks'
import ImageLeader from '../../components/ImageLeader/ImageLeader'
import SubLeader from '../../components/SubLeader/SubLeader'
import PageMeta from '../../components/PageMeta/PageMeta'
import * as BTPLStyles from '../../components/layoutComponents/bptl/BPTL.module.scss'
import SchoolReviewsLauncher from '../../components/organisms/schoolReviewsLauncher/SchoolReviewsLauncher'
import { StaticImage } from 'gatsby-plugin-image'
import Hr from '../../components/Hr/Hr'
import { type ISchoolReviewsQueryData } from '../../graphql/queries/SchoolReviewsQuery'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'
import MPUBanner from '../../components/Banners/MPUBanner/MPUBanner'
import { SchoolAwardsContext, SchoolAwardsStates } from '../../lib/utils'

export const query = graphql`
  query {
    wpgraphql {
      recommended: posts(
        where: {
          categoryName: "parenting"
          orderby: { field: DATE, order: DESC }
        }
        first: 10
      ) {
        nodes {
          id
          title
          uri
          featuredImage {
            node {
              mediaItemUrl
              altText
              spotlightDesktop: sourceUrl(size: HOMEPAGE_SPOTLIGHT_DESKTOP)
              spotlightMobile: sourceUrl(size: HOMEPAGE_SPOTLIGHT_MOBILE)
              homepagePreviewImage: sourceUrl(size: HOMEPAGE_PREVIEW)
            }
          }
          primaryCategory
          primaryCategoryURI
        }
      }
      adStarCodes {
        id
        adslot
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
      }
    }
  }
`

interface SchoolReviewsProps {
  data: ISchoolReviewsQueryData
}

const SchoolReviews = ({
  data: {
    wpgraphql: { adStarCodes, recommended, generalSettings }
  }
}: SchoolReviewsProps) => {
  const [searchTerm, setSearchTerm] = useState<string>()
  const SchoolContext = useContext(SchoolAwardsContext)

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0)
      navigate(`/school-reviews/search/?search=${searchTerm}`)
  }, [searchTerm])
  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Best Schools Guide',
          description:
            'No boring fusty reviews here! Our ‘insider’ guides give a genuine insight into each school we visit, with informal, super-useful advice from our editors.',
          image:
            'https://muddystilettos.co.uk/images/school-reviews/Leader.jpg',
          url: 'https://muddystilettos.co.uk/school-reviews/'
        }}
      />
      <Header
        siteId={generalSettings.siteId}
        siteName={generalSettings.title}
      />
      <TopBanner ads={adStarCodes} pageType={`bestschoolsguide`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <ImageLeader>
        <>
          <img
            src="/images/school-reviews/Leader.jpg"
            alt={`Best School Guide`}
          />
          <h1>Best Schools Guide</h1>
        </>
      </ImageLeader>
      <SubLeader colour={`TealBlue`}>
        <p style={{ fontSize: '12px', marginBottom: '10px' }}>
          In association with
        </p>
        <a
          href={`https://www.featherdown.co.uk/muddystilettos?utm_source=muddystilettos&utm_medium=website&utm_campaign=Summer24`}
          target="_BLANK"
          rel="noreferrer"
        >
          <img
            src={FeatherDown}
            alt={`Feather Down`}
            width={250}
            style={{ marginBottom: '18px' }}
          />
        </a>
        <SchoolReviewsLauncher handleSearchChange={setSearchTerm} />
      </SubLeader>
      <div className={`${BTPLStyles.InnerWrapper} ${BTPLStyles.Spacer}`}>
        <div className={BTPLStyles.Counties}>
          <div className={BTPLStyles.County}>
            <a href="https://muddystilettos.co.uk/school-reviews/search/boarding-school">
              <StaticImage
                src="../../../static/images/school-reviews/boarding-school.jpg"
                alt={`Boarding Schools`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Boarding Schools</span>
            </a>
          </div>
          <div className={BTPLStyles.County}>
            <a href="https://muddystilettos.co.uk/school-reviews/search/day-school">
              <StaticImage
                src="../../../static/images/school-reviews/day-schools.jpg"
                alt={`Day Schools`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Day Schools</span>
            </a>
          </div>
          <div className={BTPLStyles.County}>
            <a href="https://muddystilettos.co.uk/school-reviews/search/girls/">
              <StaticImage
                src="../../../static/images/school-reviews/girls.jpg"
                alt={`Girls`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Girls</span>
            </a>
          </div>
          <div className={BTPLStyles.County}>
            <a href="https://muddystilettos.co.uk/school-reviews/search/boys/">
              <StaticImage
                src="../../../static/images/school-reviews/boys.jpg"
                alt={`Boys`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Boys</span>
            </a>
          </div>
          <div className={BTPLStyles.County}>
            <a href="https://muddystilettos.co.uk/school-reviews/search/co-ed/">
              <StaticImage
                src="../../../static/images/school-reviews/co-ed.jpg"
                alt={`Co Ed`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Co-Ed</span>
            </a>
          </div>
          <div className={BTPLStyles.County}>
            <a href="https://muddystilettos.co.uk/school-reviews/search/early-years/">
              <StaticImage
                src="../../../static/images/school-reviews/early-years.jpg"
                alt={`Early Years`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Early Years</span>
            </a>
          </div>
          <div className={BTPLStyles.County}>
            <a href="https://muddystilettos.co.uk/school-reviews/search/all-through-school/">
              <StaticImage
                src="../../../static/images/school-reviews/all-through-school.jpg"
                alt={`All Through School`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>All Through School</span>
            </a>
          </div>
          <div className={BTPLStyles.County}>
            <a href="https://muddystilettos.co.uk/school-reviews/search/prep-school/">
              <StaticImage
                src="../../../static/images/school-reviews/prep-school.jpg"
                alt={`Prep School`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Prep School</span>
            </a>
          </div>
          <div className={BTPLStyles.County}>
            <a href="https://muddystilettos.co.uk/school-reviews/search/senior-school/">
              <StaticImage
                src="../../../static/images/school-reviews/senior-school.jpg"
                alt={`Senior School`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Senior School</span>
            </a>
          </div>
        </div>
        <Hr />
      </div>
      <MPUBanner adSlots={['Row1MPU1', 'Row1MPU2', 'Row1MPU3', 'Row1MPU4']} />
      <Blocks
        title={`You may also like`}
        posts={recommended.nodes}
        hasSpotlight
        backgroundColourOverride={`LightPink`}
      />
      <CTARow order={11} colour={`TealBlue`}>
        <div className={BTPLStyles.BSACTA}>
          <StaticImage
            src="../../../static/best-schools-awards/best-schools-awards-white.png"
            alt={`Best School Awards logo`}
            className={BTPLStyles.BSALogo}
          />
          <p>In association with</p>
          <StaticImage
            src="../../../static/best-schools-awards/debretts-education-gold.png"
            alt={`Debrett's Education logo`}
            className={BTPLStyles.DebrettsLogo}
          />
        </div>
        <p>
          A modern perspective on what it takes to be a brilliant independent
          school in the UK.
        </p>
        {SchoolContext === SchoolAwardsStates.WINNERS ? (
          <a href="https://muddystilettos.co.uk/best-schools-awards/results">
            Meet the Winners
          </a>
        ) : (
          <>
            {SchoolContext === SchoolAwardsStates.FINALISTS ? (
              <a href="https://muddystilettos.co.uk/best-schools-awards/results">
                Meet the Finalists
              </a>
            ) : (
              <a href="https://muddystilettos.co.uk/best-schools-awards/">
                Find out how to enter
              </a>
            )}
          </>
        )}

        <p></p>
      </CTARow>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default SchoolReviews
