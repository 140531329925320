import React, { ReactNode } from 'react'
import * as CTARowStyles from './CTARow.module.scss'

interface CTARowProps {
  children: ReactNode
  colour?: string
  order?: number
}

const matchColour: Record<string, string> = {
  Black: CTARowStyles.Black,
  TealBlue: CTARowStyles.TealBlue,
  Red: CTARowStyles.Red
}

const CTARow = ({ children, colour = 'Red', order }: CTARowProps) => {
  return (
    <div
      className={`${CTARowStyles.Wrapper} ${matchColour[colour]}`}
      style={{ '--order': order } as React.CSSProperties}
    >
      {children}
    </div>
  )
}

export default CTARow
