import GTMBodyScript from '../GTMScript/GTMBodyScript'
import GTMScript from '../GTMScript'
import QuantcastScript from '../QuantcastScript'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { MULTISITE_COUNTIES } from '../../lib/data/Sites'

interface PageMetaProps {
  pageData: {
    egSettingTwitter?: string | null
    url?: string | null
    title?: string | null
    description?: string | null
    siteId?: number | null
    image?: string | null
  }
}

const PageMeta = ({ pageData }: PageMetaProps) => {
  const { egSettingTwitter, url, title, description, siteId, image } = pageData
  const siteTitle = `${title?.replace('&amp;', '&')} | Muddy Stilettos`
  const subDomain = url ? url.split('.')[0].replace('https://', '') : ''
  const directory = url ? url.split('/')[3] : ''

  const countyInfo = MULTISITE_COUNTIES.filter(c => c.id === siteId)[0]

  const link =
    siteId === 1
      ? url?.replace(`//${subDomain}.`, `//`)
      : url
          ?.replace(`/${directory}/`, '/')
          .replace(`//${subDomain}.`, `//${directory}.`)

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en'
        }}
      >
        <title>{siteTitle}</title>
        {countyInfo?.siteVerification && (
          <meta
            name="google-site-verification"
            content={countyInfo.siteVerification}
          />
        )}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={siteTitle} />
        <meta
          name="description"
          content={
            description
              ? description
              : 'Muddy Stilettos is an intelligent, witty and indispensable guide to the countryside, helping women make the most of their precious time.'
          }
        />
        <meta
          property="og:description"
          content={
            description
              ? description
              : 'Muddy Stilettos is an intelligent, witty and indispensable guide to the countryside, helping women make the most of their precious time.'
          }
        />
        <meta property="og:url" content={link} />
        <meta property="og:site_name" content={`${siteTitle}`} />
        {image && <meta property="og:image" content={image} />}
        {image && <meta property="og:image:secure_url" content={image} />}
        <meta property="og:image:width" content="640" />
        <meta property="og:image:height" content="443" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content={
            description
              ? description
              : 'Muddy Stilettos is an intelligent, witty and indispensable guide to the countryside, helping women make the most of their precious time.'
          }
        />
        <meta name="twitter:title" content={`${siteTitle}`} />
        {egSettingTwitter && (
          <meta name="twitter:site" content={egSettingTwitter} />
        )}
        {image && <meta name="twitter:image" content={image} />}
        <link
          rel="preload"
          href="https://use.typekit.net/uwk5uqd.js"
          as="script"
        />
        <link
          rel="preconnect"
          href="https://scontent-lhr8-1.cdninstagram.com"
        />
        <link rel="preconnect" href="https://ad1.adfarm1.adition.com" />
      </Helmet>
      <GTMScript />
      {/* <QuantcastScript/> */}
      <GTMBodyScript />
    </>
  )
}

export default PageMeta
