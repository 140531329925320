import React, { useEffect } from 'react'

const GTMScript: React.FC = () => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.id = 'gtag'
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-K54ZKGS');
      `
      document.head.appendChild(script)

      return () => {
        document.head.removeChild(script)
      }
    }
  }, [])

  return null
}

export default GTMScript
