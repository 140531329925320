import React, { ReactNode } from 'react'
import * as SubLeaderStyles from './SubLeader.module.scss'

interface SubLeaderProps {
  children: ReactNode
  colour?: string
}

const SubLeader = ({ children, colour }: SubLeaderProps) => {
  return (
    <div
      className={`${SubLeaderStyles.Wrapper} ${
        colour === 'TealBlue' ? SubLeaderStyles.TealBlue : ''
      } ${colour === 'LightBlue' ? SubLeaderStyles.LightBlue : ''}`}
    >
      <div className={SubLeaderStyles.InnerWrapper}>{children}</div>
    </div>
  )
}

export default SubLeader
