import React, { useState, useEffect } from 'react'
// eslint-disable-next-line node/no-missing-import
import { navigate } from '@reach/router'
import { CATEGORIES } from '../../../lib/data/SchoolReviews'

import * as BTPLStyles from '../../layoutComponents/bptl/BPTL.module.scss'

interface SchoolReviewsLauncherProps {
  handleSearchChange?: (searchTerm: string) => void
  showH1?: boolean
  selectedCategory?: string
  currentValue?: string
}

const SchoolReviewsLauncher = ({
  handleSearchChange,
  showH1,
  currentValue,
  selectedCategory
}: SchoolReviewsLauncherProps) => {
  const [searchTerm, setSearchTerm] = useState<string>()
  const [searchButtonValid, setSearchButtonValid] = useState<boolean>(false)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(`/school-reviews/search/${event.target.value}`)
  }

  const handleInputChange = () => {
    handleSearchChange && handleSearchChange(searchTerm ?? '')
  }

  useEffect(() => {
    if (searchTerm !== currentValue) {
      setSearchButtonValid(true)
    } else {
      setSearchButtonValid(false)
    }
  }, [searchTerm])

  useEffect(() => {
    if (currentValue) setSearchTerm(currentValue)
  }, [currentValue])

  return (
    <>
      {showH1 && <h1>School Reviews</h1>}
      <p>
        Unique, independent, honest. Our ‘insider’ guides give a genuine insight
        into each school we visit, with informal, super-useful advice from our
        editors – parents, just like you.
      </p>
      <div className={BTPLStyles.Launcher}>
        {handleSearchChange && (
          <input
            type="search"
            placeholder="Search for..."
            defaultValue={currentValue}
            onChange={e => setSearchTerm(e.target.value)}
          />
        )}
        <select
          name="category"
          onChange={handleSelectChange}
          defaultValue={selectedCategory ?? ''}
        >
          <option disabled value="">
            ... Or choose a category...
          </option>
          {Object.entries(CATEGORIES).map((value, index) => (
            <option key={index} value={value[0]}>
              {value[1]}
            </option>
          ))}
        </select>

        <button
          disabled={!searchButtonValid}
          className={BTPLStyles.LauncherButton}
          onClick={handleInputChange}
        >
          Search
        </button>
      </div>
    </>
  )
}

export default SchoolReviewsLauncher
