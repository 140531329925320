// extracted by mini-css-extract-plugin
export var BSACTA = "BPTL-module--BSACTA--64c1e";
export var BSALogo = "BPTL-module--BSALogo--8a7aa";
export var Counties = "BPTL-module--Counties--493ea";
export var County = "BPTL-module--County--20f0e";
export var CountyList = "BPTL-module--CountyList--2e6dd";
export var CountyMap = "BPTL-module--CountyMap--dff29";
export var DebrettsLogo = "BPTL-module--DebrettsLogo--59595";
export var DoubleWidth = "BPTL-module--DoubleWidth--4e3e8";
export var HighlightBlock = "BPTL-module--HighlightBlock--db6d1";
export var HighlightButton = "BPTL-module--HighlightButton--ebf34";
export var HighlightButtonRow = "BPTL-module--HighlightButtonRow--3763c";
export var HighlightImage = "BPTL-module--HighlightImage--dfc3d";
export var HighlightText = "BPTL-module--HighlightText--953c3";
export var InnerWrapper = "BPTL-module--InnerWrapper--ee8df";
export var Launcher = "BPTL-module--Launcher--91414";
export var LauncherButton = "BPTL-module--LauncherButton--30251";
export var NationalWinnerBadge = "BPTL-module--NationalWinnerBadge--db559";
export var PromoImage = "BPTL-module--PromoImage--c4335";
export var ReaderVoteOverlay = "BPTL-module--ReaderVoteOverlay--7d001";
export var Shader = "BPTL-module--Shader--fb0f8";
export var Spacer = "BPTL-module--Spacer--e885a";
export var SponsoredBy = "BPTL-module--SponsoredBy--259fc";
export var SponsorsLogo = "BPTL-module--SponsorsLogo--27823";
export var Title = "BPTL-module--Title--ba642";
export var VoteLead = "BPTL-module--VoteLead--3f02a";
export var WinnerLarge = "BPTL-module--WinnerLarge--86597";
export var WinnerSmall = "BPTL-module--WinnerSmall--a8485";
export var WinnerTown = "BPTL-module--WinnerTown--0eed1";
export var Wrapper = "BPTL-module--Wrapper--78a45";