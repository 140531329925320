import React, { ReactNode } from 'react'
import * as WrapperStyles from './ImageLeader.module.scss'

interface ImageLeaderProps {
  children: ReactNode
  short?: boolean;
}

const ImageLeader = ({ children, short }: ImageLeaderProps) => {
  return <div className={`${WrapperStyles.Wrapper} ${short && WrapperStyles.Short}`}>{children}</div>
}

export default ImageLeader
