import React, { useEffect, useRef, useState } from 'react'
// import * as TopBannerStyles from './TopBanner.module.scss'
import { AdStarBlock } from '../../../types'
// import classNames from 'classnames'

declare global {
  interface Window {
    adition: any
  }
}

interface TopBannerProps {
  category?: string
  subCategory?: string
  pageType: string
  ads: AdStarBlock[]
  showMPUs?: boolean
  showFooterLB?: boolean
  showAdditionalMPUs?: number
}

const TopBanner = ({
  category,
  subCategory,
  pageType,
  ads,
  showMPUs
}: TopBannerProps) => {
  if (!ads) return null

  const refreshInterval = useRef<NodeJS.Timeout | null>(null)
  const [adition, setAdition] = useState<any>(null)
  const adsMap = new Map(
    ads.map(object => {
      return [object.adslot, object.id]
    })
  )
  useEffect(() => {
    if (adition && adition !== null) {
      // eslint-disable-next-line
    // @ts-ignore
      const qded = adition
      const isMobile = window.matchMedia('(max-width: 768px)').matches

      qded?.srq?.push(async function (api: any) {
        api
          .registerAdfarm('ad1.adfarm1.adition.com')
          .setProfile('category', category || '')
          .setProfile('subCategory', subCategory || '')
          .setProfile('pageType', pageType)
          .setProfile('refresh', true)

        const DMPU = adsMap.get('dmpu')

        // First of all, sort out the MPUs
        const mpus = [...adsMap.keys()]
          .filter(key => key && key !== 'dmpu' && key?.includes('mpu'))
          .map(key => {
            const transformedKey =
              key &&
              key.replace(/mpu/g, 'MPU').replace(/^\w/, c => c.toUpperCase())
            return transformedKey
          })

        const requestedSlots = ads?.filter(
          key =>
            key.adslot !== 'dmpu' &&
            key?.adslot?.includes('mpu') &&
            (isMobile ? key?.adslot?.includes('mpu1') : true)
        )

        // if (showMPUs) {
        const correctMPUs = requestedSlots?.map((slot, i) => {
          return {
            adslot: mpus.find(
              ad => ad?.toLowerCase() === slot?.adslot?.toLowerCase()
            ),
            id: adsMap.get(mpus[i]?.toLowerCase())
          }
        })
        // }

        // Now do the rest
        const otherAds = [...adsMap.keys()].filter(
          key =>
            key && !key.includes('mpu') && !key.toLowerCase().includes('mobile')
        )

        const correctLBs = otherAds?.map(slot => {
          const base = isMobile ? `${slot}Mobile` : slot
          return {
            adslot: slot && slot?.charAt(0).toUpperCase() + slot?.slice(1),
            id: adsMap.get(base)
          }
        })

        const allAds = showMPUs ? [...correctLBs, ...correctMPUs] : correctLBs

        const configureAds = async (allAds: AdStarBlock[]) => {
          const promises = allAds.map(async ad => {
            if (ad?.adslot && ad?.id) {
              await api.configureRenderSlot(ad?.adslot).setContentunitId(ad?.id)
            }
          })

          await Promise.all(promises)
        }

        try {
          await configureAds(allAds)

          await api.configureRenderSlot('DMPU').setContentunitId(DMPU)

          api.load().completeRendering()
          allAds.forEach(ad => {
            api.renderSlot(ad?.adslot)
          })
        } catch (error) {
          // Handle errors if needed
          console.error(error)
        }

        // Disabled ad-refreshing every 15 seconds

        const reloadAds = () => {
          // api.load(['TopLB'])
          api.load(['TopLB']).completeRendering()
        }

        refreshInterval.current = setInterval(reloadAds, 15000)
      })
    }

    return () => {
      if (refreshInterval.current) clearInterval(refreshInterval.current)
    }
  }, [adition])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    const checkAdition = () => {
      if (typeof window !== 'undefined' && 'adition' in window) {
        if ('ready' in window.adition) {
          setAdition(window.adition)
          if (intervalId) clearInterval(intervalId) // Clear the interval if it has been set
        }
      }
    }

    intervalId = setInterval(checkAdition, 1000) // Check every second

    return () => {
      // Clean up on component unmount
      if (intervalId) clearInterval(intervalId)
    }
  }, [])

  return <></>
}

export default TopBanner
