const CATEGORIES: Record<string, string> = {
  'boarding-school': 'Boarding Schools',
  'day-school': 'Day Schools',
  girls: 'Girls',
  boys: 'Boys',
  'co-ed': 'Co-Ed',
  'early-years': 'Early Years',
  'prep-school': 'Prep Schools',
  'all-through-school': 'All Through Schools',
  'senior-school': 'Senior Schools',
  'online-school': 'Online Schools'
}

export { CATEGORIES }
