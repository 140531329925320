import React from 'react'

const GTMBodyScript: React.FC = () => {
  return (
    <>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-K54ZKGS"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>`
        }}
      />
      <script
        async={true}
        dangerouslySetInnerHTML={{
          __html: `
      var currentUrl = window.location.href;
      
        // Push the current URL to the GTM data layer
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
      'event': 'pageview',
      'pageUrl': currentUrl
        });
        `
        }}
      />
    </>
  )
}

export default GTMBodyScript
